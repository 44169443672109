import React, { Component } from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./app.config";
import CollectInfomation from "./pages/CollectInfomation";
import Home from "./pages/Home";
import SubordinateDetails from "./pages/SubordinateDetails";
import ReturnWorkList from "./pages/ReturnWorkList";
import ScanCode from "./pages/ScanCode";
import RedirectToLive from "./pages/RedirectToLive";

import axios from "axios";
const getWXConfig = async () => {
  try {
    let url;
    let u = navigator.userAgent;
    let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; //android终端
    let isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
    if (isAndroid) {
      url = window.location.href;
    } else if (isiOS) {
      url = window.wx_js_url || window.location.href;
    }
    let res = await axios.get(
      "https://finisar26.realsun.me:1101/api/wx/GetSignature",
      {
        params: {
          requesturl: url,
        },
      }
    );
    if (res.data.error === 0) {
      let { data } = res.data;
      await window.wx.config({
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: data.AppId, // 必填，公众号的唯一标识
        timestamp: data.Timestamp, // 必填，生成签名的时间戳
        nonceStr: data.NonceStr, // 必填，生成签名的随机串
        signature: data.Signature, // 必填，签名，见附录1
        jsApiList: ["hideAllNonBaseMenuItem", "getLocation", "openLocation"], // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
      });
      window.wx.ready(() => {
        window.wx.hideAllNonBaseMenuItem();
      });
      window.wx.error((res) => {
        console.log("调用微信jsapi返回的状态:", res.errMsg);
      });
    } else {
      console.error("error:  " + res.data);
    }
  } catch (error) {
    console.error(error);
  }
};
class App extends Component {
  componentDidMount() {
    if (!window.wx_js_url) {
      window.wx_js_url = window.location.href;
    }
    getWXConfig();
  }

  render() {
    return (
      <div className="App" style={{ width: "100%", height: "100vh" }}>
        <Router>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route
              exact
              path="/collect-infomation"
              component={CollectInfomation}
            />
            <Route
              exact
              path="/subordinate-details"
              component={SubordinateDetails}
            />
            <Route exact path="/return-work-list" component={ReturnWorkList} />
            <Route exact path="/scan-code" component={ScanCode} />
            <Route exact path="/redirect-to-live" component={RedirectToLive} />
            <Route render={() => <div>404</div>} />
          </Switch>
        </Router>
      </div>
    );
  }
}

export default App;
