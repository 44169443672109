import React from "react";
import logo from "./logo.png";

const styles = {
  footer: {
    position: "fixed",
    bottom: 0,
    left: 0,
    width: "100%",
    borderTop: "1px solid rgba(0,0,0,.1)"
  },
  style1: {},
  style2: {
    background: "rgba(40,40,40,1)",
    color: "#999999",
    padding: "8px 0",
    textAlign: "center"
  }
};
export default function() {
  return (
    <div className="staff__footer" style={styles.footer}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          padding: "8px 0",
          background: "#ffffff"
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <img src={logo} style={{ height: 32, width: 32 }} alt="logo" />
          <p style={{ fontSize: 14, textAlign: "left", paddingLeft: 8 }}>
            丽章公益 助力抗“疫”
          </p>
        </div>
      </div>
      <div style={styles.style2}>
        Together to defeat the virus. RealSun sponsored.
      </div>
    </div>
  );
}
