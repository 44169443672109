import React from "react";
import { Checkbox as ACheckbox } from "antd-mobile";
import "./Checkbox.scss";

class Checkbox extends React.PureComponent {
  static defaultProps = {
    value: "",
    options: []
  };
  checkedBox = new Set();
  constructor(props) {
    super(props);
    const { options } = this.props;
    if (this.props.value) {
      let initValue = this.props.value.split(";").filter(item => {
        return options.find(option => option.value === item);
      });
      initValue.forEach(item => {
        this.checkedBox.add(item);
      });
    }
  }
  handleOnchange = (checked, v) => {
    if (checked) {
      this.checkedBox.add(v);
    } else {
      this.checkedBox.delete(v);
    }
    const value = Array.from(this.checkedBox.values()).join(";");
    this.props.onChange(value);
  };
  render() {
    const { options, value } = this.props;
    return (
      <div className="health-checkbox">
        {options.map(item => {
          const checked = value.includes(item.value);
          return (
            <ACheckbox
              key={item.value}
              checked={checked}
              onChange={e => {
                this.handleOnchange(e.target.checked, item.value);
              }}
            >
              {item.label}
            </ACheckbox>
          );
        })}
      </div>
    );
  }
}

export default Checkbox;
