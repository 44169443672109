import React from "react";
import {
  List,
  InputItem,
  DatePicker,
  Picker,
  Toast,
  ImagePicker,
  TextareaItem
} from "antd-mobile";
import { uploadFile, fixIOSWXBug } from "../utils";
import moment from "moment";
import Checkbox from "../../Checkbox";

export default function FormItem(props) {
  const {
    colInfo,
    record,
    dataJSON,
    locationCity,
    form,
    filterFunc,
    screenHeight,
    setVoiceInputField
  } = props;
  const { getFieldDecorator, getFieldValue, setFieldsValue } = form;
  const { ColValType, ColType } = colInfo;
  let input = null;
  let defaultValue = undefined;
  //上传图片
  if (colInfo.showPictures === true) {
    defaultValue =
      record && record[colInfo.ColName]
        ? [{ url: record[colInfo.ColName], id: record[colInfo.ColName] }]
        : [];
    const files = getFieldValue(colInfo.ColName) || [];
    return (
      <div style={{ height: 200, width: 200 }}>
        {getFieldDecorator(colInfo.ColName, {
          rules: [{ required: true }],
          initialValue: defaultValue,
          valuePropName: "files"
        })(
          <ImagePicker
            length={1}
            selectable={files.length < 1}
            onChange={(files, type, index) => {
              if (type === "add" && files.length > 0) {
                const type = files[0].file.type.split("/");
                Toast.loading("上传图片中...");
                uploadFile(
                  files[0].file,
                  window.baseURL +
                    "api/AliyunOss/PutOneImageObject?bucketname=nutritiontower&srctype=" +
                    type[1],
                  "cloud"
                ).then(
                  result => {
                    setFieldsValue({
                      [colInfo.ColName]: [{ url: result, id: result }]
                    });
                    Toast.hide();
                  },
                  err => {
                    //图片上传异常！
                    Toast.fail(err.message);
                    setFieldsValue({
                      [colInfo.ColName]: []
                    });
                  }
                );
              }
            }}
          />
        )}
      </div>
    );
  }
  //省市区镇 联动
  if (colInfo.isAdminLocation === true) {
    defaultValue = record && record[colInfo.ColName];
    if (defaultValue) {
      defaultValue = defaultValue.split(",");
    }
    input = getFieldDecorator(colInfo.ColName, {
      rules: [{ required: true }],
      initialValue: defaultValue
    })(
      <Picker data={dataJSON} cols={4}>
        <List.Item arrow="horizontal"></List.Item>
      </Picker>
    );
    return <div>{input}</div>;
  }
  if (ColValType === 1) {
    //简单的下拉选择
    const data = colInfo.ValueOptions.map(item => {
      return { label: item, value: item };
    });
    defaultValue =
      record && record[colInfo.ColName] ? [record[colInfo.ColName]] : null;
    input = getFieldDecorator(colInfo.ColName, {
      rules: [{ required: true }],
      initialValue: defaultValue
    })(
      <Picker data={data} cols={1}>
        <List.Item arrow="horizontal"></List.Item>
      </Picker>
    );
  } else if (ColValType === 0) {
    if (ColType === 1) {
      //文本输入
      defaultValue = record && record[colInfo.ColName];
      if (colInfo.ColName === "city") {
        defaultValue = defaultValue || locationCity;
      }
      if (colInfo.isMultiLine) {
        //多行文本
        input = getFieldDecorator(colInfo.ColName, {
          rules: [{ required: true }],
          initialValue: defaultValue
        })(
          <TextareaItem
            placeholder={`请输入${colInfo.ColNotes}`}
            autoHeight
            style={{ marginRight: 35 }}
            onBlur={() => fixIOSWXBug(screenHeight)}
            className="mr-text-area"
          />
        );
        return (
          <div style={{ position: "relative" }}>
            <div
              style={{
                position: "absolute",
                right: 15,
                top: "calc(50% - 10px)",
                zIndex: 1
              }}
              onClick={() => {
                setVoiceInputField(colInfo.ColName);
              }}
            >
              <svg
                t="1581304278001"
                className="icon"
                viewBox="0 0 1024 1024"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                p-id="2284"
                width="20"
                height="20"
              >
                <path
                  d="M819.2 529.0496v-8.533333a34.133333 34.133333 0 0 0-68.266667 0v8.533333c0 131.754667-107.178667 238.933333-238.933333 238.933333s-238.933333-107.178667-238.933333-238.933333v-8.533333a34.133333 34.133333 0 0 0-68.266667 0v8.533333c0 157.832533 119.688533 288.136533 273.066667 305.186133V938.666667H307.2a34.133333 34.133333 0 0 0 0 68.266666h409.6a34.133333 34.133333 0 0 0 0-68.266666H546.133333v-104.430934c153.378133-17.0496 273.066667-147.336533 273.066667-305.186133z"
                  fill="#515151"
                  p-id="2285"
                ></path>
                <path
                  d="M512 699.716267c98.816 0 179.2-80.384 179.2-179.2v-324.266667c0-98.816-80.384-179.2-179.2-179.2s-179.2 80.384-179.2 179.2v324.266667c0 98.816 80.384 179.2 179.2 179.2z"
                  fill="#515151"
                  p-id="2286"
                ></path>
              </svg>
            </div>
            {input}
          </div>
        );
      } else {
        //单行文本
        input = getFieldDecorator(colInfo.ColName, {
          rules: [{ required: true }],
          initialValue: defaultValue
        })(
          <InputItem
            placeholder={`请输入${colInfo.ColNotes}`}
            extra={
              <div
                onClick={() => {
                  setVoiceInputField(colInfo.ColName);
                }}
              >
                <svg
                  t="1581304278001"
                  className="icon"
                  viewBox="0 0 1024 1024"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  p-id="2284"
                  width="20"
                  height="20"
                >
                  <path
                    d="M819.2 529.0496v-8.533333a34.133333 34.133333 0 0 0-68.266667 0v8.533333c0 131.754667-107.178667 238.933333-238.933333 238.933333s-238.933333-107.178667-238.933333-238.933333v-8.533333a34.133333 34.133333 0 0 0-68.266667 0v8.533333c0 157.832533 119.688533 288.136533 273.066667 305.186133V938.666667H307.2a34.133333 34.133333 0 0 0 0 68.266666h409.6a34.133333 34.133333 0 0 0 0-68.266666H546.133333v-104.430934c153.378133-17.0496 273.066667-147.336533 273.066667-305.186133z"
                    fill="#515151"
                    p-id="2285"
                  ></path>
                  <path
                    d="M512 699.716267c98.816 0 179.2-80.384 179.2-179.2v-324.266667c0-98.816-80.384-179.2-179.2-179.2s-179.2 80.384-179.2 179.2v324.266667c0 98.816 80.384 179.2 179.2 179.2z"
                    fill="#515151"
                    p-id="2286"
                  ></path>
                </svg>
              </div>
            }
            onBlur={() => fixIOSWXBug(screenHeight)}
          />
        );
      }
    } else if (ColType === 4) {
      //日期选择
      defaultValue = record && record[colInfo.ColName];
      defaultValue = defaultValue && moment(defaultValue).toDate();
      input = getFieldDecorator(colInfo.ColName, {
        rules: [{ required: true }],
        initialValue: defaultValue
      })(
        <DatePicker mode="date" title="请选择日期">
          <List.Item arrow="horizontal"></List.Item>
        </DatePicker>
      );
    }
  } else if (ColValType === 14) {
    //下拉项为某张表的数据
    if (colInfo.ColOptionDictData.IsMutiSelectCol) {
      //多选
      defaultValue = record && record[colInfo.ColName];
      const data = colInfo.ListOfColOptions.map(item => {
        return {
          label: item.displayColValue,
          value: item.valueColValue
        };
      });
      input = getFieldDecorator(colInfo.ColName, {
        rules: [{ required: true }],
        initialValue: defaultValue || ""
      })(<Checkbox options={data} />);
    } else {
      //单选
      const data = colInfo.ListOfColOptions.map(item => {
        return {
          label: item.displayColValue,
          value: item.valueColValue
        };
      });
      defaultValue =
        record && record[colInfo.ColName] ? [record[colInfo.ColName]] : null;
      input = getFieldDecorator(colInfo.ColName, {
        rules: [{ required: true }],
        initialValue: defaultValue
      })(
        <Picker data={data} cols={1}>
          <List.Item arrow="horizontal"></List.Item>
        </Picker>
      );
    }
  } else if (ColValType === 8) {
    //下拉项为高级字典
    const AdvDictionaryListData = colInfo.AdvDictionaryListData[0];
    const values = AdvDictionaryListData.DictionaryFilterCol.map(item => {
      const value = getFieldValue(item.Column1);
      if (Array.isArray(value)) {
        return value[0];
      } else {
        return value;
      }
    });
    defaultValue =
      record && record[colInfo.ColName] ? [record[colInfo.ColName]] : null;
    const data = filterFunc ? filterFunc(...values) : [];
    let currentValue = getFieldValue(colInfo.ColName);
    if (currentValue && !data.some(i => i.value === currentValue[0])) {
      setFieldsValue({ [colInfo.ColName]: null });
    }
    input = getFieldDecorator(colInfo.ColName, {
      rules: [{ required: true }],
      initialValue: defaultValue
    })(
      <Picker data={data} cols={1}>
        <List.Item arrow="horizontal"></List.Item>
      </Picker>
    );
  }

  return <div>{input}</div>;
}
