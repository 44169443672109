import http from "lz-request/lib/http";
import { getItem } from "./util";
// http.setDefaultBaseURL("http://kingofdinner.realsun.me:1101/");
http.setDefaultBaseURL(window.baseURL);

// http.setRequestInterceptors(function(config) {
//   return config;
// });

// 默认 options
// http.setDefaultOptions({
//   withCredentials: true
// });

// 请求拦截
http.setRequestInterceptors(
  function(config) {
    // 请求头加上 token
    const userInfo = JSON.parse(getItem("userInfo"));
    let token = userInfo && userInfo.AccessToken;
    let userCode = userInfo && userInfo.UserCode;
    if (token && userCode) {
      config.headers.accessToken = token;
      config.headers.userCode = userCode;
    }
    return config;
  },
  function(error) {
    return error;
  }
);

// 响应拦截
http.setResponseInterceptors(
  response => {
    const res = response.data;
    if (
      (res &&
        (res.error === 0 ||
          res.error === "0" ||
          res.Error === 0 ||
          res.Error === "0" ||
          res.OpResult === "Y")) ||
      res === "ok"
    ) {
      return response;
    } else {
      throw new Error(res.ErrMsg || res.message || res.ErrorMsg);
    }
  },
  error => {
    // if (error.response.status === 401) {
    //   window.location.href = "/login";
    // }
    return error;
  }
);

/**
 * 通过 unionid 登录
 * 参数：{ unionid, loginMethod = 'weixin' }
 * 1. unionid：unionid
 * 2. loginMethod：登录方式，固定为 'weixin'
 */
http.createApi("loginByUnionid", {
  method: "post",
  baseURL: "https://finisar.realsun.me:9092/",
  url: "/api/Account/Login"
});

/**
 *登录
 */
http.createApi("login", {
  method: "post",
  url: "/api/Account/Login"
});

/**
 * 根据身份证查询记录
 * 参数：{ unionid, loginMethod = 'weixin' }
 */
http.createApi("select", {
  method: "get",
  baseURL: "https://finisar.realsun.me:9092/",
  url:
    "/api/200/table/Retrieve?resid={resid}&subresid={subresid}&cmswhere={cmswhere}"
});

/**
 * 通过手机号、openid、unionid 来获取验证码
 * 参数：{ telephone, unionid, openid }
 * 1. telephone：手机号
 * 2. unionid：unionid
 * 3. openid：openid
 */
http.createApi("getVerCode", {
  method: "get",
  // baseURL: 'https://finisar.realsun.me/',
  url: "api/SMS/SMS_SendValidCode"
});

/**
 * 注册
 * 参数：{ telephone, unionid, openid, validCode, personid, method }
 * 1. telephone：手机号
 * 2. unionid：unionid
 * 3. openid: openid
 * 4. personid：身份证
 * 5. method：method
 * openid,telephone,unionid,validCode,workNum
 */
http.createApi("register", {
  method: "get",
  // baseURL: 'https://finisar.realsun.me/',
  url: "api/Account/Register"
});

http.createApi("getWXConfig", {
  method: "get",
  baseURL: "http://kingofdinner.realsun.me:1201/",
  url: `api/wx/GetSignature?requesturl=${window.location.href}`
});

http.createApi("getPublicTable", {
  method: "get",
  url: "api/100/table/Public/Retrieve"
});

http.createApi("isUserExist", {
  method: "get",
  url: "api/Account/IsUserExist"
});

http.createApi("saveRecordAndSubTables", {
  method: "post",
  url: "api/200/table/Save"
});

http.createApi("getRecordAndSubTables", {
  method: "get",
  url: "api/200/table/Retrieve"
});

http.createApi("getColumnsDefine", {
  method: "get",
  url: "api/100/table/RetrieveColumnsDefine"
});

/**
 * 批量保存数据（100数据结构）
 */
http.createApi("StartSaveTask", {
  method: "post",
  url: "api/tablethread/StartSaveTask"
});

/**
 * 根据id获取任务状况
 */
http.createApi("RetrieveSaveTask", {
  method: "get",
  url: "api/tablethread/RetrieveSaveTask"
});

// http://kingofdinner.realsun.me:1201/api//wx/GetSignature?requesturl=http://finisarinterview.reaslun.me
export default http;
