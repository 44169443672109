// 开发环境
if (process.env.NODE_ENV === "development") {
  window.authBaseUrl = "https://finisar.realsun.me/RSAuth/OAuth?returnUrl=";
  window.returnUrl = "http://localhost:3000/";
  window.returnRegisterURL = "http://localhost:3000/register";
  window.host = "http://localhost:3000";
  window.baseURL = "http://kingofdinner.realsun.me:1101/";
  // 生产环境
} else {
  window.authBaseUrl = "https://finisar.realsun.me/RSAuth/OAuth?returnUrl=";
  window.returnUrl = "https://finisar26.realsun.me/";
  window.returnRegisterURL = "http://finisarinterview.realsun.me/register";
  window.host = "http://finisarinterview.realsun.me";
  window.baseURL = "https://finisar26.realsun.me:1101/";
}
