import React from "react";
import { Button, Tabs, Toast, Badge, SearchBar } from "antd-mobile";
import http from "../../util/api";
import moment from "moment";
import classNames from "classnames";

import "./SubordinateDetails.scss";

const resid = 634139859520;
const subresid = 634140170298;

const todaystr = moment().format("YYYY-MM-DD");

class SubordinateDetails extends React.Component {
  state = {
    employees: [], //所有员工
    finishedEmployee: [], //未完成员工
    unfinishedEmployee: [], //已完成的员工
    selectedEmployee: null, //选中的员工
    isDirectReports: true, //是否在查看直接下属
    directSearchKey: "",
    inDirectSearchKey: ""
  };

  async componentDidMount() {
    Toast.loading("加载数据中...", 0);
    await this.getData();
    Toast.hide();
  }

  getData = async () => {
    try {
      const res = await http().getRecordAndSubTables({ resid, subresid });
      const data = res.data.data;
      let finishedEmployee = [],
        unfinishedEmployee = [];
      data.forEach(item => {
        // console.log("本人防疫采集是否提交", item.C3_634129625713);
        // console.log("下属未提交人数", item.C3_634143230060);
        //如果本人防疫采集是否提交=Y 并且下属未提交人数=0，就算已提交，否则算未提交
        if (item.C3_634129625713 === "Y" && item.C3_634143230060 === 0) {
          finishedEmployee.push(item);
        } else {
          unfinishedEmployee.push(item);
        }
      });
      this.setState({ finishedEmployee, unfinishedEmployee });
    } catch (error) {
      console.error(error);
      Toast.fail(error.message);
    }
  };

  //渲染直接下属
  renderDirectReports = (finishedEmployee, unfinishedEmployee) => {
    const tabs = [
      { title: <Badge text={unfinishedEmployee.length}>未完成</Badge> },
      { title: <Badge text={finishedEmployee.length}>已完成</Badge> }
    ];
    const { directSearchKey } = this.state;
    return (
      <>
        <div className="subordinate-details__header">
          <span>直接下属</span>
          <span>{todaystr}</span>
        </div>
        <div>
          <Tabs tabs={tabs} swipeable={false}>
            <div className="subordinate-details__tabpane">
              <SearchBar
                placeholder="搜索员工工号/姓名"
                onCancel={() =>
                  this.setState({
                    directSearchKey: ""
                  })
                }
                onChange={v => {
                  this.setState({ directSearchKey: v });
                }}
                value={directSearchKey}
              />
              <div className="subordinate-details__list">
                {unfinishedEmployee.map(item => {
                  const name = item.C3_634066603591;
                  const number = item.C3_634066603834;
                  let isShow = true;
                  if (directSearchKey) {
                    isShow =
                      name.includes(directSearchKey) ||
                      number.includes(directSearchKey);
                  }
                  const currnetDate = moment(item.C3_634129565352);
                  return (
                    isShow && (
                      <div
                        className="subordinate-details__list-item"
                        key={item.REC_ID}
                      >
                        <div className="subordinate-details__list-item__header">
                          <div className="subordinate-details__list-item__header__employeeinfo">
                            <span>姓名：{name}</span>
                            <span style={{ marginLeft: 20 }}>
                              工号：{item.C3_634066603834}
                            </span>
                          </div>
                          {item.C3_634143195801 && (
                            <span
                              className="subordinate-details__list-item__header__view-detail"
                              onClick={() =>
                                this.setState({
                                  isDirectReports: false,
                                  selectedEmployee: item
                                })
                              }
                            >
                              查看详情
                            </span>
                          )}
                        </div>
                        <div className="subordinate-details__list-item__footer">
                          {currnetDate.month() + 1}月{currnetDate.date()}
                          号的防疫数据，
                          <span
                            className={classNames({
                              "subordinate-details__error":
                                item.C3_634129625713 !== "Y"
                            })}
                          >
                            {name}
                            {item.C3_634129625713 === "Y" ? "已" : "未"}提交
                            {item.C3_634143230060 > 0 ? "，" : "。"}
                          </span>
                          {item.C3_634143230060 > 0 && (
                            <span className="subordinate-details__error">
                              {name}的下属还有{item.C3_634143230060}
                              人未提交。
                            </span>
                          )}
                        </div>
                      </div>
                    )
                  );
                })}
              </div>
            </div>
            <div className="subordinate-details__tabpane">
              <SearchBar
                placeholder="搜索员工工号/姓名"
                onCancel={() =>
                  this.setState({
                    directSearchKey: ""
                  })
                }
                onChange={v => {
                  this.setState({ directSearchKey: v });
                }}
                value={directSearchKey}
              />
              <div className="subordinate-details__list">
                {finishedEmployee.map(item => {
                  const name = item.C3_634066603591;
                  const number = item.C3_634066603834;
                  let isShow = true;
                  if (directSearchKey) {
                    isShow =
                      name.includes(directSearchKey) ||
                      number.includes(directSearchKey);
                  }
                  const currnetDate = moment(item.C3_634129565352);
                  return (
                    isShow && (
                      <div
                        className="subordinate-details__list-item"
                        key={item.REC_ID}
                      >
                        <div className="subordinate-details__list-item__header">
                          <div className="subordinate-details__list-item__header__employeeinfo">
                            <span>姓名：{name}</span>
                            <span style={{ marginLeft: 20 }}>
                              工号：{number}
                            </span>
                          </div>
                          {item.C3_634143195801 && (
                            <span
                              className="subordinate-details__list-item__header__view-detail"
                              onClick={() =>
                                this.setState({
                                  isDirectReports: false,
                                  selectedEmployee: item
                                })
                              }
                            >
                              查看详情
                            </span>
                          )}
                        </div>
                        <div className="subordinate-details__list-item__footer">
                          {currnetDate.month() + 1}月{currnetDate.date()}
                          号的防疫数据，
                          <span
                            className={classNames({
                              "subordinate-details__error":
                                item.C3_634129625713 !== "Y"
                            })}
                          >
                            {name}已提交。
                          </span>
                        </div>
                      </div>
                    )
                  );
                })}
              </div>
            </div>
          </Tabs>
        </div>
        <div className="subordinate-details__footer">
          <Button type="primary" onClick={() => this.props.history.goBack()}>
            返回
          </Button>
        </div>
      </>
    );
  };
  //渲染间接下属
  renderIndirectReports = selectedEmployee => {
    const emplyees = selectedEmployee[subresid];
    const finishedEmployee =
        emplyees &&
        emplyees.filter(item => {
          return item.C3_634129625713 === "Y" && item.C3_634143230060 === 0;
        }),
      unfinishedEmployee =
        emplyees &&
        emplyees.filter(item => {
          return item.C3_634129625713 !== "Y" || item.C3_634143230060 > 0;
        });
    const tabs = [
      { title: <Badge text={unfinishedEmployee.length}>未完成</Badge> },
      { title: <Badge text={finishedEmployee.length}>已完成</Badge> }
    ];
    const { inDirectSearchKey } = this.state;
    return (
      <>
        <div className="subordinate-details__header">
          <span>{selectedEmployee.C3_634066603591}的下属</span>
          <span>{todaystr}</span>
        </div>
        <div>
          <Tabs tabs={tabs} swipeable={false}>
            <div className="subordinate-details__tabpane">
              <SearchBar
                placeholder="搜索员工工号/姓名"
                onCancel={() =>
                  this.setState({
                    inDirectSearchKey: ""
                  })
                }
                onChange={v => {
                  this.setState({ inDirectSearchKey: v });
                }}
                value={inDirectSearchKey}
              />
              <div className="subordinate-details__list">
                {unfinishedEmployee.map(item => {
                  const name = item.C3_634066603591;
                  const number = item.C3_634066603834;
                  let isShow = true;
                  if (inDirectSearchKey) {
                    isShow =
                      name.includes(inDirectSearchKey) ||
                      number.includes(inDirectSearchKey);
                  }
                  const currnetDate = moment(item.C3_634129565352);
                  return (
                    isShow && (
                      <div
                        className="subordinate-details__list-item"
                        key={item.REC_ID}
                      >
                        <div className="subordinate-details__list-item__header">
                          <div className="subordinate-details__list-item__header__employeeinfo">
                            <span>姓名：{name}</span>
                            <span style={{ marginLeft: 20 }}>
                              工号：{number}
                            </span>
                          </div>
                        </div>
                        <div className="subordinate-details__list-item__footer">
                          {currnetDate.month() + 1}月{currnetDate.date()}
                          号的防疫数据，
                          <span
                            className={classNames({
                              "subordinate-details__error":
                                item.C3_634129625713 !== "Y"
                            })}
                          >
                            {name}
                            {item.C3_634129625713 === "Y" ? "已" : "未"}提交
                            {item.C3_634143230060 > 0 ? "，" : "。"}
                          </span>
                          {item.C3_634143230060 > 0 && (
                            <span className="subordinate-details__error">
                              {name}的下属还有{item.C3_634143230060}
                              人未提交。
                            </span>
                          )}
                        </div>
                      </div>
                    )
                  );
                })}
              </div>
            </div>
            <div className="subordinate-details__tabpane">
              <SearchBar
                placeholder="搜索员工工号/姓名"
                onCancel={() =>
                  this.setState({
                    inDirectSearchKey: ""
                  })
                }
                value={inDirectSearchKey}
                onChange={v => {
                  this.setState({ inDirectSearchKey: v });
                }}
              />
              <div className="subordinate-details__list">
                {finishedEmployee.map(item => {
                  const name = item.C3_634066603591;
                  const number = item.C3_634066603834;
                  let isShow = true;
                  if (inDirectSearchKey) {
                    isShow =
                      name.includes(inDirectSearchKey) ||
                      number.includes(inDirectSearchKey);
                  }
                  const currnetDate = moment(item.C3_634129565352);

                  return (
                    isShow && (
                      <div
                        className="subordinate-details__list-item"
                        key={item.REC_ID}
                      >
                        <div className="subordinate-details__list-item__header">
                          <div className="subordinate-details__list-item__header__employeeinfo">
                            <span>姓名：{name}</span>
                            <span style={{ marginLeft: 20 }}>
                              工号：{number}
                            </span>
                          </div>
                        </div>
                        <div className="subordinate-details__list-item__footer">
                          {currnetDate.month() + 1}月{currnetDate.date()}
                          号的防疫数据，
                          <span
                            className={classNames({
                              "subordinate-details__error":
                                item.C3_634129625713 !== "Y"
                            })}
                          >
                            {name}已提交。
                          </span>
                        </div>
                      </div>
                    )
                  );
                })}
              </div>
            </div>
          </Tabs>
        </div>
        <div className="subordinate-details__footer">
          <Button
            type="primary"
            onClick={() => {
              this.setState({
                isDirectReports: true,
                selectedEmployee: null
              });
            }}
          >
            返回
          </Button>
        </div>
      </>
    );
  };

  render() {
    const {
      finishedEmployee,
      unfinishedEmployee,
      isDirectReports,
      selectedEmployee
    } = this.state;
    return (
      <div className="subordinate-details">
        {isDirectReports
          ? this.renderDirectReports(finishedEmployee, unfinishedEmployee)
          : this.renderIndirectReports(selectedEmployee)}
      </div>
    );
  }
}

export default SubordinateDetails;
