import React from "react";
import "./Home.scss";
import img from "./img/banner.png";
import moment from "moment";
import { setItem } from "../../util/util";
import http from "../../util/api";
import { Toast } from "antd-mobile";
import qs from "qs";
import Footer from "../../components/Footer";

const todayStr = moment().format("YYYYMMDD");
const today = moment();
const resid = 634045767301;

class Home extends React.Component {
  state = {
    name: "",
    logs: [],
    todayLog: null
  };
  _unionid = "";
  async componentDidMount() {
    Toast.show("加载数据中...", 0);
    try {
      const search = document.location.search;
      const querystring = search.substring(1);
      const { unionid } = qs.parse(querystring);
      if (unionid) {
        setItem("unionid", unionid);
        this._unionid = unionid;
        await this.login(unionid);
      }
    } catch (error) {
      console.error(error);
    }
    await this.getLog();
    Toast.hide();
  }

  login = async unionid => {
    try {
      const res = await http().login({
        loginMethod: "unionid",
        unionid
      });
      setItem("UserCode", res.data.UserCode);
      setItem("AccessToken", res.data.AccessToken);
      setItem("SysUserInfo", JSON.stringify(res.data.SysUserInfo));
      setItem("userInfo", JSON.stringify(res.data));
      this.setState({
        name: res.data.SysUserInfo.UserName
      });
    } catch (error) {
      console.error(error);
      Toast.fail(error.message);
    }
  };

  /**
   * 获取个人所有健康日志
   */
  getLog = async () => {
    try {
      const res = await http().getTable({
        resid,
        getcolumninfo: 1
      });
      this.setState({ cmscolumninfo: res.data.cmscolumninfo });
      const { data } = res.data;
      if (data.length) {
        const todayLog = data.find(item => {
          return item.dates === todayStr;
        });
        this.setState({ logs: data, todayLog });
      }
    } catch (error) {
      console.error(error);
      Toast.fail(error.message);
    }
  };

  getDayZh = day => {
    let dayZh = "";
    switch (day) {
      case 1:
        dayZh = "一";
        break;
      case 2:
        dayZh = "二";
        break;
      case 3:
        dayZh = "三";
        break;
      case 4:
        dayZh = "四";
        break;
      case 5:
        dayZh = "五";
        break;
      case 6:
        dayZh = "六";
        break;
      case 7:
        dayZh = "日";
        break;

      default:
        dayZh = "";
        break;
    }
    return dayZh;
  };
  render() {
    const { logs, name, todayLog } = this.state;
    const todayHaveFill = todayLog && todayLog.submit === "Y"; //有今日记录并且submit值为Y
    // const todayHaveFill = false;
    return (
      <div className="home">
        <img className="home__header" src={img} alt="" />
        <div className="home__content">
          <p className="home__content__date">
            {today.get("year")}年{today.get("month") + 1}月{today.get("date")}日
            周{this.getDayZh(today.day())}
          </p>
          {todayHaveFill ? (
            <p className="home__content__days">
              {name}已与企业共同抗击疫情{logs.length}天
            </p>
          ) : (
            <p className="home__content__days--nofill">
              {name}尚未填写今日调查，请尽快填写
            </p>
          )}
          <div className="home__content__footer">
            {todayHaveFill ? (
              <button
                className="home__button "
                style={{ background: "rgba(24,144,255,1)" }}
                onClick={() => {
                  this.props.history.push(
                    "/collect-infomation?unionid=" + this._unionid
                  );
                }}
              >
                查看/修改今日疫情调查
              </button>
            ) : (
              <button
                className="home__button "
                style={{ background: "rgba(250,173,20,1)" }}
                onClick={() => {
                  this.props.history.push(
                    "/collect-infomation?unionid=" + this._unionid
                  );
                }}
              >
                填写今日疫情调查
              </button>
            )}
          </div>
        </div>

        <div className="home__content">
          <div className="home__content__footer">
            <button
              className="home__button "
              style={{ background: "rgba(19,194,194,1)" }}
              onClick={() => this.props.history.push("/subordinate-details")}
            >
              查看下属疫情反馈
            </button>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Home;
