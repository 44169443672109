import React from "react";
import { Modal, TextareaItem } from "antd-mobile";
import mic from "./mic.png";

class WXVoiceInput extends React.Component {
  state = {
    translateResult: "",
    soundRecording: false
  };

  handleTouchStart = e => {
    this.timeOutEvent = setTimeout(() => {
      e.preventDefault();
      this.setState({
        soundRecording: true,
        translateResult: ""
      });
      window.wx.startRecord();
    }, 600);
  };

  handleTouchEnd = e => {
    e.preventDefault();
    this.setState({
      soundRecording: false
    });
    if (this.timeOutEvent) {
      clearTimeout(this.timeOutEvent);
      window.wx.stopRecord({
        success: res => {
          const localId = res.localId;
          window.wx.translateVoice({
            localId, // 需要识别的音频的本地Id，由录音相关接口获得
            isShowProgressTips: 1, // 默认为1，显示进度提示
            success: res => {
              let translateResult = res.translateResult.substring(
                0,
                res.translateResult.length - 1
              );
              this.setState({
                translateResult
              });
            }
          });
        }
      });
    }
  };

  handleComfirm = () => {
    const { onTranslateConfirm, onClose } = this.props;
    const { translateResult } = this.state;
    this.setState({
      soundRecording: false
    });
    if (translateResult) {
      onTranslateConfirm && onTranslateConfirm(translateResult);
      this.setState({ translateResult: "" });
    }
    onClose && onClose();
  };

  render() {
    const { visible } = this.props;
    const { translateResult, soundRecording } = this.state;
    return (
      <Modal
        popup
        visible={visible}
        animationType="slide-up"
        wrapClassName="voice-modal"
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "20px 30px",
            userSelect: "none"
          }}
        >
          <span
            style={{ userSelect: "none" }}
            onClick={() => {
              this.setState({ translateResult: "" });
            }}
          >
            清空
          </span>
          <span
            style={{ color: "#1890FF", userSelect: "none" }}
            onClick={this.handleComfirm}
          >
            确认
          </span>
        </div>
        <div
          style={{
            color: "#13C2C2",
            height: 100,
            userSelect: "none",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          {soundRecording ? (
            <p style={{ userSelect: "none" }}>
              语音输入中... <br />
              松开手指完成输入
            </p>
          ) : (
            <p style={{ userSelect: "none" }}>按住下方按钮开始录音</p>
          )}
        </div>
        <div>
          <TextareaItem
            title="翻译结果"
            placeholder="翻译结果"
            onChange={v => {
              this.setState({ translateResult: v });
            }}
            autoHeight
            value={translateResult}
            onBlur={() => {
              this.fixIOSWXBug();
            }}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            padding: "20px 0",
            userSelect: "none"
          }}
        >
          <div
            style={{
              width: 80,
              height: 80,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              background: "#13C2C2",
              borderRadius: "50%",
              userSelect: "none"
            }}
            onTouchStart={this.handleTouchStart}
            onTouchEnd={this.handleTouchEnd}
          >
            <div
              style={{
                height: 40,
                width: 40,
                background: `url(${mic}) `,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                userSelect: "none"
              }}
            />
          </div>
        </div>
      </Modal>
    );
  }
}

export default WXVoiceInput;
