import React from "react";
import http from "../../util/api";
import { Button, Toast, Badge, Tabs, SearchBar } from "antd-mobile";
import moment from "moment";
import "./ReturnWorkList.scss";
import memoize from "memoize-one";

const resid = "635941843314";
const tomarrow = moment()
  .add(1, "day")
  .format("YYYY-MM-DD");

class ReturnWorkList extends React.Component {
  state = {
    list: [],
    tomarrowList: [],
    searchKey: "",
    loading: false
  };
  async componentDidMount() {
    Toast.loading("获取数据中...");
    this.setState({ loading: true });
    await this.getList();
    this.setState({ loading: false });
    Toast.hide();
  }

  getList = async () => {
    try {
      const res = await http().getTable({
        resid
      });
      const tomarrowList = res.data.data.filter(item => {
        return item.C3_635941789806 === tomarrow;
      });
      this.setState({ list: res.data.data, tomarrowList });
    } catch (error) {
      console.error(error);
      Toast.fail(error.message);
    }
  };
  filterList = memoize((list, filterText) => {
    return list.filter(item => {
      return (
        item.C3_634066603834.includes(filterText) ||
        item.C3_634066603591.includes(filterText)
      );
    });
  });
  filterTomorrawList = memoize((list, filterText) => {
    return list.filter(item => {
      return (
        item.C3_634066603834.includes(filterText) ||
        item.C3_634066603591.includes(filterText)
      );
    });
  });
  render() {
    const { list, loading, tomarrowList, searchKey } = this.state;

    const filtedList = this.filterList(list, searchKey);
    const filtedTomarrowList = this.filterList(tomarrowList, searchKey);
    const tabs = [
      { title: <Badge text={filtedList.length}>总复工名单</Badge> },
      { title: <Badge text={filtedTomarrowList.length}>明日可复工名单</Badge> }
    ];
    return (
      <div className="return-work">
        <h2 className="return-work__title">
          <Badge text={list.length}>可复工名单</Badge>
        </h2>
        <SearchBar
          placeholder="搜索员工工号/姓名"
          onCancel={() =>
            this.setState({
              searchKey: ""
            })
          }
          onChange={v => {
            this.setState({ searchKey: v });
          }}
          value={searchKey}
        />
        <Tabs tabs={tabs} swipeable={false}>
          <div className="return-work__tabpane">
            <div className="return-work__list">
              {filtedList.length === 0 && !loading && (
                <div
                  style={{
                    fontSize: 24,
                    textAlign: "center",
                    padding: "24px 0",
                    background: "#fff"
                  }}
                >
                  暂无
                </div>
              )}
              {!loading &&
                filtedList.map(item => {
                  return (
                    <div className="return-work__list-item" key={item.REC_ID}>
                      <div className="list-item-number">
                        <label>工号:</label>
                        <span>{item.C3_634066603834}</span>
                      </div>
                      <div className="list-item-name">
                        <label>姓名:</label>
                        <span>{item.C3_634066603591}</span>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
          <div className="return-work__tabpane">
            <div className="return-work__list">
              {filtedTomarrowList.length === 0 && !loading && (
                <div
                  style={{
                    fontSize: 24,
                    textAlign: "center",
                    padding: "24px 0",
                    background: "#fff"
                  }}
                >
                  暂无
                </div>
              )}
              {!loading &&
                filtedTomarrowList.map(item => {
                  return (
                    <div className="return-work__list-item" key={item.REC_ID}>
                      <div className="list-item-number">
                        <label>工号:</label>
                        <span>{item.C3_634066603834}</span>
                      </div>
                      <div className="list-item-name">
                        <label>姓名:</label>
                        <span>{item.C3_634066603591}</span>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </Tabs>

        <div className="return-work__footer">
          <Button type="primary" onClick={() => this.props.history.goBack()}>
            返回
          </Button>
        </div>
      </div>
    );
  }
}

export default ReturnWorkList;
