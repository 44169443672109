import React from "react";
import classNames from "classnames";
import { Button, Modal, Toast } from "antd-mobile";
import { createForm } from "rc-form";
import FormItem from "./FormItem";
import http from "../../util/api";
import memoize from "memoize-one";
import "./Form.scss";
import WXVoiceInput from "../WXVoiceInput";

const Antalert = Modal.alert;

class Form extends React.Component {
  state = {
    cmscolumninfo: [],
    voiceInputField: "",
    translateResult: "",
    soundRecording: false,
    src: "",
    pictureVisible: false,
    currentItem: {}
  };

  async componentDidMount() {
    this.screenHeight = document.documentElement.clientHeight;
    Toast.loading("加载中...", 0);
    await this.getLog();
    document.oncontextmenu = function(e) {
      e.preventDefault();
    };
    Toast.hide();
  }

  _dictionaryData = {}; // key为表id，value为字典数据数组
  getDictionaryData = async resid => {
    try {
      const res = await http().getTable({
        resid
      });
      this._dictionaryData[resid] = res.data.data;
    } catch (error) {
      console.error(error);
      Toast.fail(error.message);
    }
  };

  _filterFuncs = {}; //key为内部字段，如C3_812778471254，value为过滤函数
  /**
   * 获取字段定义
   */
  getLog = async () => {
    const { resid } = this.props;
    try {
      const res = await http().getTable({
        resid,
        cmswhere: "0 = 1",
        getcolumninfo: 1
      });
      const cmscolumninfo = res.data.cmscolumninfo;
      this.setState({ cmscolumninfo });
      for (let index = 0; index < cmscolumninfo.length; index++) {
        let item = cmscolumninfo[index];
        const colInfo = item[item.id];
        if (colInfo.ColValType === 8) {
          const AdvDictionaryListData = colInfo.AdvDictionaryListData[0];
          this._filterFuncs[colInfo.ColName] = memoize((...rest) => {
            let filtedData = this._dictionaryData[AdvDictionaryListData.ResID2];
            AdvDictionaryListData.DictionaryFilterCol.forEach(
              (condition, index) => {
                const value = rest[index];
                if (value) {
                  filtedData = filtedData.filter(
                    data => data[condition.Column2] === value
                  );
                }
              }
            );
            filtedData = filtedData.map(item => {
              return {
                label:
                  item[
                    AdvDictionaryListData.MatchAndReferenceCols[0].CDZ2_COL2
                  ],
                value:
                  item[AdvDictionaryListData.MatchAndReferenceCols[0].CDZ2_COL2]
              };
            });
            return filtedData;
          });
          await this.getDictionaryData(AdvDictionaryListData.ResID2);
        }
      }
    } catch (error) {
      console.error(error);
      Toast.fail(error.message);
    }
  };

  submitConfirm = () => {
    this.props.form.validateFields(async (error, value) => {
      if (!error) {
        Antalert("确认提交？", "请仔细确认您填写的信息后提交", [
          { text: "取消", onPress: () => {} },
          {
            text: "确认",
            onPress: () => this.handleSubmit(value)
          }
        ]);
      } else {
        console.log(value);
        Toast.info("信息未填完，红色标记的问题即为未填问题", 2);
      }
    });
  };

  /**
   * 提交
   */
  handleSubmit = async value => {
    const data = {};
    const keys = Object.keys(value);
    // keys.forEach(item => console.log(item));
    keys.forEach(item => {
      let isArray;
      try {
        isArray = Array.isArray(value[item]);
      } catch (e) {
        console.log(e);
      }
      if (isArray) {
        const _value = value[item][0];
        // //判断是否是个对象
        if (typeof _value === "object") {
          if (_value.url) {
            //如果有url属性
            data[item] = _value.url;
          }
        } else {
          data[item] = value[item].join(",");
        }
      } else {
        data[item] = value[item];
      }
    });
    this.props.handleSubmit(data);
  };

  setVoiceInputField = voiceInputField => {
    this.setState({ voiceInputField });
  };

  closeVoiceInput = () => {
    this.setState({ voiceInputField: "" });
  };

  handleTranslateConfirm = result => {
    const { voiceInputField } = this.state;
    this.props.form.setFieldsValue({
      [voiceInputField]: result
    });
  };

  render() {
    const { form, record, locationCity } = this.props;
    const { getFieldValue, getFieldError } = form;
    const {
      voiceInputField,
      currentItem,
      cmscolumninfo,
      src,
      pictureVisible
    } = this.state;
    return (
      <div className="form">
        <div className="form__questions">
          {cmscolumninfo.map((item, index) => {
            const colInfo = item[item.id];
            let showCond = colInfo.showCond;
            let isShow = true;
            if (showCond) {
              //后台配置的条件
              try {
                showCond = JSON.parse(showCond);
              } catch (error) {
                console.log("error", item.text, showCond);
              }
              //当满足后台配置的条件是时，显示该字段
              if (showCond.length > 1) {
                for (let i = 0; i < showCond.length; i++) {
                  let currentValue = getFieldValue(showCond[i].confield);
                  if (!currentValue) {
                    //未选值 始终显示该字段
                    break;
                  } else {
                    //选了值
                    let condValue = showCond[i].convalue;
                    let conlogic = showCond[i].conlogic;
                    currentValue = currentValue[0];
                    if (conlogic === "或") {
                      if (i === 0) {
                        isShow = false;
                      }
                      isShow = isShow || currentValue === condValue;
                    } else if (conlogic === "并") {
                      isShow = isShow && currentValue === condValue;
                      if (!isShow) {
                        break;
                      }
                    }
                  }
                }
              } else if (showCond.length === 1) {
                let currentValue = getFieldValue(showCond[0].confield);
                if (currentValue) {
                  //选了值
                  currentValue = currentValue && currentValue[0];
                  isShow = currentValue === showCond[0].convalue;
                }
              }
            }
            const isImportant = item.id === "C3_635441599421";
            let style = {};
            if (isImportant) {
              style = {
                background: "#52c41a",
                color: "#ffffff"
              };
            }
            const pictures =
              colInfo.fieldPictures && colInfo.fieldPictures.split(",");
            return (
              isShow && (
                <div
                  key={item.id}
                  className="question"
                  onClick={() => {
                    this.setState({ currentItem: item });
                  }}
                >
                  <div
                    className={classNames("question-title", {
                      selected: currentItem && currentItem.id === item.id,
                      error: getFieldError(item.id)
                    })}
                    style={style}
                  >
                    {index + 1}. {colInfo.ColNotes}
                    {pictures && "附件："}
                    {pictures &&
                      pictures.map((src, i) => {
                        return (
                          <span
                            style={{
                              textDecoration: "underline",
                              color: "#096dd9",
                              fontSize: "1.3em",
                              marginRight: 12
                            }}
                            onClick={() =>
                              this.setState({ src, pictureVisible: true })
                            }
                          >
                            图片{i + 1}
                          </span>
                        );
                      })}
                  </div>
                  <div className="question-answer">
                    <FormItem
                      colInfo={colInfo}
                      form={form}
                      filterFunc={this._filterFuncs[colInfo.ColName]}
                      screenHeight={this.screenHeight}
                      setVoiceInputField={this.setVoiceInputField}
                      record={record}
                      locationCity={locationCity}
                    />
                  </div>
                </div>
              )
            );
          })}
        </div>
        <div className="form__footer">
          <Button type="primary" onClick={this.submitConfirm}>
            提交
          </Button>
        </div>
        <Modal
          visible={pictureVisible}
          transparent
          style={{ width: "100vw" }}
          maskClosable
          onClose={() => this.setState({ pictureVisible: false })}
        >
          <img src={src} width="100%" height="auto" alt="" />
        </Modal>
        <WXVoiceInput
          visible={voiceInputField}
          onTranslateConfirm={this.handleTranslateConfirm}
          onClose={this.closeVoiceInput}
        />
      </div>
    );
  }
}

export default createForm()(Form);
