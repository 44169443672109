import { province } from "./province";
import { city } from "./city";
import { country } from "./country";

export const setItem = (key, value) => {
  return localStorage.setItem(key, value);
};

export const getItem = key => {
  return localStorage.getItem(key);
};

export const removeItem = key => {
  return localStorage.removeItem(key);
};

export const fixIOSWXBug = () => {
  document.body.scrollTop = document.documentElement.scrollTop = 0;
};
export const getRegions = () => {
  province.forEach(provinceItem => {
    // 省
    provinceItem.label = provinceItem.name;
    provinceItem.value = provinceItem.name;
    provinceItem.key = provinceItem.name;

    const children = city[provinceItem.id];
    if (children) {
      provinceItem.children = children;
      // 市
      provinceItem.children.forEach(cityItem => {
        cityItem.label = cityItem.name;
        cityItem.value = cityItem.name;
        cityItem.children = country[cityItem.id];
        cityItem.key = cityItem.name;

        const children = country[cityItem.id];
        if (children) {
          cityItem.children = children;
          // 区
          cityItem.children.forEach(countryItem => {
            countryItem.label = countryItem.name;
            countryItem.value = countryItem.name;
            countryItem.key = countryItem.name;
          });
        }
      });
    }
  });
  return province;
};

/**
 * 需引入微信js SDK和百度map jsSDK
 *   <script src="https://res.wx.qq.com/open/js/jweixin-1.4.0.js"></script>
 *  并获取微信认证授权，在jsApiList数组需增加'getLocation'
 *
 * 百度ak需替换
 *  <script src="https://api.map.baidu.com/api?v=2.0&ak=v5iv8VFMzkdqSCiRfdYctK7mahUROLhn"></script>
 * @param {function} callback 参数为定位的信息，result.addressComponents.city为城市，result.address为详细地址
 */
export const wxLocation = callback => {
  let latitude;
  let longitude;
  window.wx.getLocation({
    type: "wgs84", // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
    success: res => {
      latitude = res.latitude; // 纬度，浮点数，范围为90 ~ -90
      longitude = res.longitude; // 经度，浮点数，范围为180 ~ -180。
      // var map = new window.lengthBMap.Map("l-map");
      // map.centerAndZoom(new window.BMap.Point(116.404, 39.915), 11);
      // 创建地理编码实例
      let myGeo = new window.BMap.Geocoder();
      // 根据坐标得到地址描述
      myGeo.getLocation(new window.BMap.Point(longitude, latitude), result => {
        if (result) {
          callback(result);
        } else {
          alert("定位失败，请刷新页面重新定位");
        }
      });
    },
    error: () => {
      alert("定位失败，请刷新页面重新定位");
    },
    failure: () => {
      alert("定位失败，请刷新页面重新定位");
    }
  });
};
