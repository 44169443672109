import React from "react";
import moment from "moment";
import { getItem, setItem } from "../../util/util";

class RedirectToLive extends React.Component {
  componentDidMount() {
    let userid = getItem("userid");
    if (!userid || userid == "undefined") {
      const now = moment();
      userid = now.unix() * 1000 + now.millisecond();
      setItem("userid", userid);
    }
    const url = `${window.liveHost}appid=${window.appid}&domain=${
      window.domain
    }&return=${window.return}&action=${window.action}&user=${userid}&goto=${
      window.goto
    }`;
    window.location.href = url;
  }
  render() {
    return null;
  }
}

export default RedirectToLive;
