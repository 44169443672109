import locationJSON from "./pcas.json";

/**
 * 省市区镇联动需要的数据
 */
export const getDataJSON = jsonData => {
  const dataJSON = [];

  const provinces = Object.keys(jsonData); //所有省
  provinces.forEach(p => {
    const provinceOBJ = { label: p, value: p, children: [] };
    dataJSON.push(provinceOBJ);
    const cities = locationJSON[p]; //某个省下所有市
    Object.keys(cities).forEach(c => {
      const cityOBJ = { label: c, value: c, children: [] };
      provinceOBJ.children.push(cityOBJ);
      const areas = cities[c]; //某个市下所有区、县、市
      if (!Array.isArray(areas)) {
        Object.keys(areas).forEach(a => {
          const areaOBJ = { label: a, value: a, children: [] };
          cityOBJ.children.push(areaOBJ);
          areas[a].forEach(s => {
            areaOBJ.children.push({ label: s, value: s });
          });
        });
      } else {
        areas.forEach(a => {
          const areaOBJ = { label: a, value: a, children: [] };
          cityOBJ.children.push(areaOBJ);
        });
      }
    });
  });
  return dataJSON;
};

/**
 * 上传图片
 */
export const uploadFile = (file, url, mode) => {
  return new Promise((resolve, reject) => {
    let fd = new FormData();
    fd.append("file", file, file.name);
    const xhr = new XMLHttpRequest();
    xhr.open("POST", url);
    xhr.onload = () => {
      const data = JSON.parse(xhr.response);
      if (xhr.status === 200 && (data.error === 0 || data.error === "0")) {
        let imgUrl;
        if (mode === "local") {
          imgUrl = data.httpfilename;
        } else if (mode === "cloud") {
          imgUrl = data.data;
        }
        resolve(imgUrl);
      } else {
        reject(data);
      }
    };
    xhr.send(fd);
  });
};

function isWeixinBrowser() {
  var ua = navigator.userAgent.toLowerCase();
  var result = /micromessenger/.test(ua) ? true : false;
  return result;
}
function isIos() {
  const u = navigator.userAgent;
  const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
  return isiOS;
}
const _isIos = isIos();
const _isWeixinBrowser = isWeixinBrowser();
export const fixIOSWXBug = screenHeight => {
  if (_isWeixinBrowser && _isIos) {
    window.scroll(0, 0);
    window.innerHeight = window.outerHeight = screenHeight;
  }
};
