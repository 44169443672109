import React from "react";
import http from "../../util/api";
import { setItem, getItem } from "../../util/util";
import qs from "qs";
import "./CollectInfomation.scss";
import { Button, Toast, Modal } from "antd-mobile";
import moment from "moment";
import axios from "axios";
import Footer from "../../components/Footer";
import Form from "../../components/Form";

let resid = 634045767301;
const todayStr = moment().format("YYYYMMDD");

class CollectInfomation extends React.Component {
  _timer = null;
  _syswxlocation = "";
  _city = "";
  _unionid = "";
  state = {
    logs: [],
    todayLog: undefined,
    step: 1,
    name: "", //姓名
    number: "", //工号
    tipModalVislble: true
  };
  async componentDidMount() {
    const search = document.location.search;
    const querystring = search.substring(1);
    const { tableresid } = qs.parse(querystring);
    let unionid = qs.parse(querystring).unionid; //从url中取unionid
    // if (!unionid) {
    //   return (document.location.href = `${window.authBaseUrl}${window.returnUrl}collect-infomation${search}`);
    // }
    if (unionid && unionid !== "undefined") {
      setItem("unionid", unionid);
    } else {
      unionid = getItem("unionid"); //从localStorage中取unionid
      if (!unionid || unionid === "undefined") {
        return (document.location.href = `${window.authBaseUrl}${window.returnUrl}collect-infomation${search}`);
      }
    }
    // this.getLoaction();
    if (tableresid) {
      resid = tableresid;
    }
    this._unionid = unionid;
    Toast.loading("加载中...", 0);
    await this.login(unionid);
    await this.getLog();
    await this.getWXConfig();
    document.oncontextmenu = function(e) {
      e.preventDefault();
    };
    Toast.hide();
  }

  componentWillUnmount() {
    this._timer = null;
    this.getTask = null;
  }

  login = async unionid => {
    try {
      const res = await http().login({
        loginMethod: "unionid",
        unionid
      });
      setItem("UserCode", res.data.UserCode);
      setItem("AccessToken", res.data.AccessToken);
      setItem("SysUserInfo", JSON.stringify(res.data.SysUserInfo));
      setItem("userInfo", JSON.stringify(res.data));
      this.setState({
        name: res.data.SysUserInfo.UserName,
        number: res.data.SysUserInfo.UserString1
      });
    } catch (error) {
      console.error(error);
      Toast.fail(error.message);
    }
  };

  /**
   * 获取个人所有健康日志
   */
  getLog = async () => {
    try {
      const res = await http().getTable({
        resid,
        getcolumninfo: 1
      });
      const cmscolumninfo = res.data.cmscolumninfo;
      this.setState({ cmscolumninfo });
      const { data } = res.data;
      if (data.length) {
        const todayLog = data.find(item => {
          return item.dates === todayStr;
        });
        this.setState({ logs: data, todayLog });
        if (!todayLog && data.length) {
          const todayLog = data[data.length - 1];
          delete todayLog.REC_ID;
          delete todayLog.submit;
          todayLog.C3_634140688860 = null;
          todayLog.C3_634141809277 = null;
          todayLog.C3_634664295172 = null;
          todayLog.C3_634141943902 = null;
          todayLog.C3_634140688860 = null;
          this.setState({ todayLog });
        }
      }
    } catch (error) {
      console.error(error);
      Toast.fail(error.message);
    }
  };

  goHome = () => {
    this.props.history.push("/?unionid=" + this._unionid);
  };

  getLoaction = () => {
    const geolocation = new window.BMap.Geolocation();
    // 开启SDK辅助定位
    geolocation.enableSDKLocation();
    geolocation.getCurrentPosition(r => {
      if (geolocation.getStatus() === window.BMAP_STATUS_SUCCESS) {
        const city = r.address.city; //返回当前城市
        this._city = city;
        this.setState({ city });
        Toast.hide();
      } else {
        const myCity = new window.BMap.LocalCity();
        myCity.get(result => {
          const city = result.name;
          this.setState({ city });
          Toast.hide();
        });
      }
    });
  };

  getWXConfig = async () => {
    try {
      let url;
      let u = navigator.userAgent;
      let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; //android终端
      let isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if (isAndroid) {
        url = window.location.href;
      } else if (isiOS) {
        url = window.wx_js_url || window.location.href;
      }
      let res = await axios.get(
        "https://finisar26.realsun.me:1101/api/wx/GetSignature",
        {
          params: {
            requesturl: url
          }
        }
      );
      if (res.data.error === 0) {
        let { data } = res.data;
        await window.wx.config({
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: data.AppId, // 必填，公众号的唯一标识
          timestamp: data.Timestamp, // 必填，生成签名的时间戳
          nonceStr: data.NonceStr, // 必填，生成签名的随机串
          signature: data.Signature, // 必填，签名，见附录1
          jsApiList: [
            "hideAllNonBaseMenuItem",
            "getLocation",
            "openLocation",
            "startRecord",
            "stopRecord",
            "translateVoice"
          ] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
        });
        window.wx.ready(this.wxLocation);
        window.wx.error(res => {
          console.log("调用微信jsapi返回的状态:", res.errMsg);
        });
      } else {
        console.error("error:  " + res.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  wxLocation = () => {
    let latitude;
    let longitude;
    window.wx.getLocation({
      type: "wgs84", // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
      success: res => {
        latitude = res.latitude; // 纬度，浮点数，范围为90 ~ -90
        longitude = res.longitude; // 经度，浮点数，范围为180 ~ -180。
        // var map = new window.lengthBMap.Map("l-map");
        // map.centerAndZoom(new window.BMap.Point(116.404, 39.915), 11);
        // 创建地理编码实例
        let myGeo = new window.BMap.Geocoder();
        // 根据坐标得到地址描述
        myGeo.getLocation(
          new window.BMap.Point(longitude, latitude),
          result => {
            if (result) {
              this._city = result.addressComponents.city;
              this._syswxlocation = result.address;
              this.setState({ city: result.addressComponents.city });
            } else {
              alert("定位失败，请刷新页面重新定位");
            }
            Toast.hide();
          }
        );
      },
      fail: error => {
        alert("定位失败，请刷新页面重新定位");
        Toast.hide();
      }
    });
  };

  _handleSubmit = async data => {
    data = {
      ...data,
      submit: "Y",
      syscity: this._city,
      syswxlocation: this._syswxlocation,
      _id: 1,
      _state: "editoradd"
    };
    Toast.loading("提交中...", 0);
    try {
      const res = await http().StartSaveTask({
        resid,
        data: JSON.stringify([data])
      });
      res.data.taskid && this.getTask(res.data.taskid);
    } catch (error) {
      Toast.hide();
      Toast.fail(error.message);
    }
  };

  getTask = async taskid => {
    try {
      const res = await http().RetrieveSaveTask({ taskid, includeData: true });
      const data = res.data;
      if (data.IsCompleted) {
        if (data.data.result.Error !== 0) {
          Toast.fail(data.data.result.message);
          this._timer = null;
        } else {
          Toast.hide();
          this.setState({ step: 3 });
          this._timer = null;
        }
      } else {
        this._timer = setTimeout(async () => {
          if (this.getTask) {
            await this.getTask(taskid);
          }
        }, 3000);
      }
    } catch (error) {
      console.log(error);
      Toast.fail(error.message);
    }
  };

  render() {
    const { step, name, number, city, tipModalVislble, todayLog } = this.state;
    return (
      <div className="collect-infomation">
        {step === 1 && (
          <div className="collect-infomation__step1">
            <h3 className="collect-infomation__step1__header-title">
              2020春节期间员工返锡情况调查
            </h3>
            <p
              className="color-grey"
              style={{
                textAlign: "left",
                textIndent: "2em",
                padding: "0 12px",
                lineHeight: 2
              }}
            >
              根据防疫管控与复工规定，要求员工必须提供返锡日期及准确的在锡居住地址。
              <span style={{ color: "#f5222d" }}>
                请准确填写居住地、每天更新您的状态，以免影响您返回无锡后居住、进出和复工事宜。
              </span>
              请在填写信息前先行确认所属街道和社区名称。
              您可在菲尼萨工会公众号的通知中查询，或致电您居住地居委会咨询。
            </p>
            <div className="collect-infomation__step1__footer">
              <Button
                onClick={() => {
                  // return this.setState({ step: 2 });
                  if (this._city) {
                    this.setState({ step: 2 });
                  } else {
                    Toast.info("未定位城市，请点击‘重新定位’获取定位城市");
                  }
                }}
                type="primary"
                inline
              >
                开始填写
              </Button>
              <Button
                onClick={() => this.props.history.push("/return-work-list")}
                style={{ background: "#13C2C2", color: "#ffffff" }}
                inline
              >
                查看复工名单
              </Button>
              <Button
                onClick={() => this.props.history.push("/subordinate-details")}
                style={{ background: "#13C2C2", color: "#ffffff" }}
                inline
              >
                查看下属
              </Button>
            </div>
            <p
              className="collect-infomation__step1__tip color-blue "
              style={{ lineHeight: 2, padding: "0 12px" }}
            >
              填写调查前请确认您的工号和姓名，
              <br />
              如果有误，请点击左上角的叉关闭当前页面。
              <br /> 之后在“小菲员工助手”内 发消息联系客服协助解决。
            </p>
            <div className="collect-infomation__step1__employee-infomation">
              <p>姓名：{name}</p>
              <p>工号：{number}</p>
              <p>
                当前城市：{city}&nbsp;
                <span
                  style={{ color: "#1890ff", textDecoration: "underline" }}
                  onClick={() => {
                    Toast.info("重新定位中...", 0);
                    this.setState({ city: "" });
                    this.wxLocation();
                  }}
                >
                  重新定位
                </span>
              </p>
            </div>

            <Footer />
          </div>
        )}
        {step === 2 && (
          <div className="collect-infomation__step2">
            <Form
              record={todayLog}
              handleSubmit={this._handleSubmit}
              resid={resid}
              locationCity={city}
            />
            <Modal
              visible={tipModalVislble}
              transparent
              maskClosable={false}
              footer={[
                {
                  text: "我知道了",
                  onPress: () => {
                    this.setState({ tipModalVislble: false });
                  }
                }
              ]}
            >
              <p
                style={{
                  textAlign: "center",
                  fontSize: 16,
                  marginBottom: 24,
                  marginTop: 30
                }}
              >
                根据疫情防控需要，
                <br /> 所有员工必须每日提交防控调查
              </p>
              {todayLog && todayLog.submit === "Y" ? (
                <p style={{ color: "#1890FF" }}>
                  您今日已提交防控调查，可修改后再次提交
                </p>
              ) : (
                <p style={{ color: "#F5222D" }}>
                  您今日尚未提交防控调查， 在核实、修改完页面内的信息后
                  请点击提交按钮。
                </p>
              )}
            </Modal>
          </div>
        )}
        {step === 3 && (
          <div className="collect-infomation__step3">
            <h3 className="collect-infomation__step3__header-title">
              您已填写完今日疫情反馈
            </h3>
            <p className="collect-infomation__step3__tip color-grey">
              感谢您的合作，请明日继续填写。
              <br /> 抗击肺炎，人人有责。
            </p>
            <div className="collect-infomation__step3__footer">
              <Button type="primary" onClick={this.goHome}>
                返回主页
              </Button>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default CollectInfomation;
