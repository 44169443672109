import React from "react";
import "./ScanCode.scss";
import axios from "axios";
import { Toast, Modal } from "antd-mobile";
import { setItem, getItem } from "../../util/util";
import http from "../../util/api";
import qs from "qs";
import scan from "./test-scan.svg";
import moment from "moment";

class ScanCode extends React.Component {
  isWXAuthed_ = true;
  state = {
    name: "",
    result: "",
    successVisible: false
  };
  async componentDidMount() {
    const search = document.location.search;
    const querystring = search.substring(1);
    let unionid = qs.parse(querystring).unionid; //从url中取unionid
    if (unionid && unionid !== "undefined") {
      setItem("unionid", unionid);
    } else {
      unionid = getItem("unionid"); //从localStorage中取unionid
      if (!unionid || unionid === "undefined") {
        return (document.location.href = `${window.authBaseUrl}${window.returnUrl}scan-code`);
      }
    }
    Toast.loading("请稍候...", 0);
    await this.login(unionid);
  }

  login = async unionid => {
    try {
      const res = await http().login({
        loginMethod: "unionid",
        unionid
      });
      const data = res.data;
      if (data.OpResult === "Y") {
        setItem("userInfo", JSON.stringify(data));
        this.getWXConfig();
      } else {
        Toast.info(data.ErrorMsg);
      }
    } catch (error) {
      console.error(error);
      Toast.fail(error.message);
    }
  };

  getWXConfig = async () => {
    try {
      let url;
      let u = navigator.userAgent;
      let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; //android终端
      let isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if (isAndroid) {
        url = window.location.href;
      } else if (isiOS) {
        url = window.wx_js_url || window.location.href;
      }
      let res = await axios.get(
        "https://finisar26.realsun.me:1101/api/wx/GetSignature",
        {
          params: {
            requesturl: url
          }
        }
      );
      if (res.data.error === 0) {
        let { data } = res.data;
        await window.wx.config({
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: data.AppId, // 必填，公众号的唯一标识
          timestamp: data.Timestamp, // 必填，生成签名的时间戳
          nonceStr: data.NonceStr, // 必填，生成签名的随机串
          signature: data.Signature, // 必填，签名，见附录1
          jsApiList: ["scanQRCode"] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
        });
        Toast.hide();
        window.wx.ready(() => {
          // this.isWXAuthed_ = true;
          // this.scanCode();
        });
        window.wx.error(res => {
          this.isWXAuthed_ = false;
          alert("调用微信扫码失败，请重新进入");
        });
      } else {
        Toast.fail(res.data.message);
        console.error("error:  " + res.data);
      }
    } catch (error) {
      Toast.fail(error.message);
      console.error(error);
    }
  };
  scanCode = () => {
    if (!this.isWXAuthed_) {
      return Toast.fail("未获取微信扫码权限");
    }
    window.wx.scanQRCode({
      desc: "scanQRCode desc",
      needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
      scanType: ["qrCode", "barCode"], // 可以指定扫二维码还是一维码，默认二者都有
      success: async res => {
        const result = res.resultStr;
        try {
          Toast.loading("保存中", 0);
          const res = await http().addRecords({
            resid: "636203689761",
            data: [{ C3_636203786925: result }]
          });
          this.setState({
            name: res.data.data[0].C3_636203759719,
            result,
            successVisible: true
          });
          Toast.hide();
        } catch (error) {
          Toast.hide();
          if (error.message === "loginerror") {
          }
          Modal.alert(
            <div style={{ color: "#f5222d" }}>保存失败</div>,
            <div
              style={{
                textAlign: "center",
                color: "#f5222d",
                display: "flex"
              }}
            >
              <span>原因:</span>
              <span style={{ flex: 1, textAlign: "left" }}>
                {error.message === "loginerror"
                  ? "您可能还未绑定小菲员工助手"
                  : error.message}
              </span>
            </div>,
            []
          );
        }
      },
      error: function(err) {
        Toast.hide();
        alert("错误" + err);
        if (err.errMsg.indexOf("function_not_exist") > 0) {
          alert("版本过低请升级");
        }
      }
    });
  };
  render() {
    const { successVisible, name, result } = this.state;
    return (
      <div className="scan-code-container" style={{}}>
        <div className="scan-code" onClick={this.scanCode}>
          <img
            src={scan}
            alt=""
            style={{ height: 20, width: 20, marginRight: 12 }}
          />
          扫码
        </div>
        {successVisible && (
          <Modal
            visible={successVisible}
            transparent
            maskClosable={false}
            className="success"
            title={
              <div style={{ color: "#40a9ff", fontSize: 24 }}>保存成功</div>
            }
          >
            <div style={{ textAlign: "left", color: "#40a9ff", fontSize: 24 }}>
              <div
                style={{
                  borderBottom: "1px solid rgba(0,0,0,0.2)",
                  padding: 8
                }}
              >
                <span>姓名：</span>
                <span style={{ flex: 1, textAlign: "left" }}>{name}</span>
              </div>
              <div
                style={{
                  borderBottom: "1px solid rgba(0,0,0,0.2)",
                  padding: 8
                }}
              >
                <span>位置：</span>
                <span style={{ flex: 1, textAlign: "left" }}>{result}</span>
              </div>
              <div
                style={{
                  borderBottom: "1px solid rgba(0,0,0,0.2)",
                  padding: 8
                }}
              >
                <span>时间：</span>
                <span style={{ flex: 1, textAlign: "left" }}>
                  {moment().format("YYYY-MM-DD HH:mm")}
                </span>
              </div>
            </div>
          </Modal>
        )}
      </div>
    );
  }
}
//  http://kingofdinner.realsun.me:1201/api/Baidu/OCR/OcrIDCardByUrlImage?url=https://nutritiontower.oss-cn-hangzhou.aliyuncs.com/634840163189.jpeg
export default ScanCode;
